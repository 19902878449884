
/* General styling for dark mode */
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    background-color: #1c1c1e;
    color: #f5f5f7;
    margin: 0;
    padding: 0;
}

/* Navigation Bar (Vertical, Left for desktop and Bottom for mobile) */
.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background-color: #2c2c2e;
    padding-top: 20px;
    z-index: 1000;
}

.nav-bar ul {
    list-style-type: none;
    padding: 0;
}

.nav-bar ul li {
    margin-bottom: 10px;
}

.nav-bar ul li a {
    display: block;
    padding: 15px 20px;
    text-align: left;
    background-color: #2c2c2e;
    color: #a9a9aa;
    border-radius: 10px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-bar ul li a:hover, .nav-bar ul li a.active {
    background-color: #007aff;
    color: #fff;
}

/* Main Content: Add margin to account for the nav bar width on desktop */
.main-content {
    margin-left: 220px;
    padding: 20px;
}

/* Buttons */
button {
    background-color: #007aff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #005bb5;
}

/* Form */
input, select {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 15px;
    border: 1px solid #3a3a3c;
    border-radius: 8px;
    background-color: #2c2c2e;
    color: #f5f5f7;
    font-size: 16px;
}

input:focus, select:focus {
    outline: none;
    border-color: #007aff;
}

/* Carousel */
.carousel img {
    width: 100%;
    height: auto;
    max-width: 500px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

/* Responsive Design: Move nav bar to the bottom for mobile devices */
@media only screen and (max-width: 768px) {
    .nav-bar {
        top: auto;
        bottom: 0;
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
    }

    .nav-bar ul {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    .nav-bar ul li {
        margin-bottom: 0;
    }

    .nav-bar ul li a {
        text-align: center;
        padding: 10px 15px;
        font-size: 16px;
    }

    .main-content {
        margin-left: 0;
        margin-bottom: 60px; /* To avoid overlap with the bottom nav */
    }
}

/* Countdown styling */
.countdown {
    text-align: center;
    margin-bottom: 20px;
}

.countdown-timer {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.countdown-item {
    background-color: #007aff;
    color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 12px;
}

.countdown-item span {
    font-weight: bold;
    font-size: 24px;
    display: block;
    margin-bottom: 5px;
}

/* Beach-themed styling for Explore Boracay section */
.about-section ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.about-section li {
    background-color: #ffe4b5; /* Sandy beach color */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #333;
}

.about-section li strong {
    color: #ff6347; /* Sunset orange for highlights */
}

.about-section li ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about-section li ul li {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
    color: #007aff;
}

.about-section h4 {
    color: #1e90ff; /* Ocean blue for heading */
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Dark beach-themed styling for Explore Boracay section */
.about-section ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.about-section li {
    background-color: #2c2c2e; /* Dark sandy beach color */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #f5f5f7;
}

.about-section li strong {
    color: #ff7f50; /* Coral orange for highlights */
}

.about-section li ul {
    list-style-type: disc;
    padding-left: 20px;
}

.about-section li ul li {
    background-color: #3a3a3c; /* Slightly lighter background for nested items */
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
    color: #1e90ff; /* Ocean blue for nested items */
}

.about-section h4 {
    color: #00bfff; /* Bright sky blue for heading */
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Dark beach-themed styling for Explore Boracay section without bullet dots */
.about-section ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.about-section li {
    background-color: #2c2c2e; /* Dark sandy beach color */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #f5f5f7;
}

.about-section li strong {
    color: #1e90ff; /* Coral orange for highlights */
}

.about-section li ul {
    list-style-type: none; /* Remove bullet dots */
    padding-left: 20px;
}

.about-section li ul li {
    background-color: #3a3a3c; /* Slightly lighter background for nested items */
    padding: 10px;
    border-radius: 8px;
    margin-top: 8px;
    color: #fff; /* Ocean blue for nested items */
}

.about-section h4 {
    color: #00bfff; /* Bright sky blue for heading */
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
}
